<template>
    <div>
        <page-header :title="`Thông tin cá nhân`" :items="breadcrumbs" />
        <div>
            <b-card>
                <b-row>
                    <b-col lg="6" sm="12">
                        <b-form-group col-lg="6">
                            <label>Tên </label>
                            <b-input v-if="form.Verify == 1" v-model="form.Kyc.hoTen" placeholder="Nhập tên hiển thị"
                                disabled />
                            <b-input v-else v-model="form.Name" placeholder="Nhập tên hiển thị" />
                        </b-form-group>
                    </b-col>
                    <b-col lg="6" sm="12">
                        <b-form-group>
                            <label>Số điện thoại/email:</label>
                            <b-input v-model="form.Id" disabled />
                        </b-form-group>
                    </b-col>
                    <b-col lg="6" sm="12">
                        <label>Ảnh đại diện </label>
                        <b-form-group>
                            <a-thumbnail class="my-pond" label="Chọn Avatar" :value="form.Avatar"
                                @input="(value) => (form.Avatar = value)" />
                        </b-form-group>
                    </b-col>
                    <b-col lg="12" sm="12"> </b-col>
                    <b-col lg="6" sm="12">
                        <b-form-group>
                            <label>Tỉnh/Thành phố <span class="red">*</span></label>
                            <b-input v-model="form.Province" disabled>
                            </b-input>
                        </b-form-group>
                    </b-col>
                    <b-col lg="6" sm="12">
                        <b-form-group>
                            <label>Quận/Huyện <span class="red">*</span></label>
                            <b-form-select v-model="form.District" :options="districts">
                                <template #first>
                                    <b-form-select-option :value="null" disabled>Chọn Quận/Huyện</b-form-select-option>
                                </template>
                            </b-form-select>
                        </b-form-group>
                    </b-col>
                    <b-col lg="6" sm="12">
                        <b-form-group>
                            <label>Phường/Xã <span class="red">*</span> </label>
                            <b-form-select v-model="form.Ward" :options="wards">
                                <template #first>
                                    <b-form-select-option :value="null" disabled>Chọn Phường/Xã</b-form-select-option>
                                </template>
                            </b-form-select>
                        </b-form-group>
                    </b-col>
                    <b-col lg="6" sm="12">
                        <b-form-group>
                            <label>Địa chỉ (Số nhà, đường/thôn, xóm) <span class="red">*</span></label>
                            <b-input v-model="form.Address" placeholder="Nhập địa chỉ" />
                        </b-form-group>
                    </b-col>
                    <b-col lg="12">
                        <b-form-group>
                            <div v-if="showAddErrorMessage" class="alert alert-danger" role="alert">
                                {{ addErrorMessage }}
                            </div>
                            <b-button variant="success" class="mr-2" @click.prevent="update">
                                Lưu
                            </b-button>
                            <b-button variant="danger" @click.prevent="toHomeView">
                                Huỷ
                            </b-button>
                        </b-form-group>
                    </b-col>
                </b-row>
            </b-card>
        </div>
    </div>
</template>

<script>
import {
    Base64
} from "js-base64";
import PageHeader from "../../../components/page-header";
import {
    httpClient
} from "../../../_utils/httpClient";
import AThumbnail from "./partials/a-thumbnail";
import { mapMutations } from "vuex";
import Swal from "sweetalert2";

export default {
    name: "update",
    components: {
        PageHeader,
        AThumbnail
    },
    data: function () {
        return {
            breadcrumbs: [{
                text: "Trang chủ",
                to: "/admin/home",
            },
            {
                text: "Thông tin cá nhân",
                to: "/admin/profile",
                active: true,
            },
            ],
            form: {
                Name: "",
                Avatar: "",
                Id: "",
                Province: null,
                District: null,
                Ward: null,
                Address: "",
                Verify: 0,
            },
            provinces: [],
            districts: [],
            wards: [],
            errorMessage: "",
            showAddErrorMessage: false,
            check_district: 0
        };
    },
    watch: {
        'form.Province': {
            handler: function () {
                this.getDistricts(this.form.Province)
            },
            deep: true,
        },
        'form.District': {
            handler: function () {
                this.getWards(this.form.Province, this.form.District)
                if ((++this.check_district) > 1) {
                    this.form.Ward = null
                }
            },
            deep: true,
        },
    },
    methods: {
        ...mapMutations('user', ['setName', 'setAvatar']),
        change_img() {
            document.querySelector('.filepond--drop-label').click()
        },
        async getProfile() {
            let userData = localStorage.getItem("token").split(".")[1];
            let userInfo = JSON.parse(Base64.decode(userData)).phone;
            let response = await httpClient.get(`auth/profile`, {
                id: userInfo
            })
            this.form = {
                ...response.data.data
            };
        },
        async toHomeView() {
            await this.$router.push({
                name: "admin.home",
            });
        },
        validator() {
            if (this.form.Ward == null || this.form.Ward == "") {
                this.showAddErrorMessage = true
                this.addErrorMessage = `Phường/Xã không được trống.`
                return false
            }
            if (this.form.Address == null || this.form.Address.trim() == "") {
                this.showAddErrorMessage = true
                this.addErrorMessage = `Địa chỉ không được trống.`
                return false
            }
            return true
        },
        async getProvince() {
            let response = await httpClient.get(`config/provinces`)
            console.log(response)
            this.provinces = response.data.data
        },
        async getDistricts(province_name) {
            let response = await httpClient.get(`config/districts`, {
                params: {
                    province_name
                }
            })
            console.log(response)
            this.districts = response.data.data
        },
        async getWards(province_name, district_name) {
            let response = await httpClient.get(`config/wards`, {
                params: {
                    province_name,
                    district_name
                }
            })
            console.log(response)
            this.wards = response.data.data
        },
        async update() {
            this.showAddErrorMessage = false;
            if (!this.validator()) return;
            await Swal.queue([{
                title: 'Thay đổi thông tin cá nhân?',
                confirmButtonText: 'Xác nhận',
                confirmButtonColor: '#34c38f',
                showCancelButton: true,
                cancelButtonText: 'Hủy bỏ',
                cancelButtonColor: '#f46a6a',
                showLoaderOnConfirm: true,
                preConfirm: async () => {
                    let response = await httpClient.post(`auth/profile`, {
                        ...this.form
                    });
                    if (response.status === 200) {
                        localStorage.setItem("token", response.data.token);
                        this.setName(this.form.Name)
                        this.setAvatar(this.form.Avatar)
                        Swal.insertQueueStep({
                            title: 'Thay đổi thông tin cá nhân thành công',
                        });
                    } else {
                        Swal.insertQueueStep({
                            title: 'Thay đổi thông tin cá nhân không thành công',
                        });
                    }
                    return true;
                },
            },]);
        },
    },
    mounted() {
        document.querySelector('.my-pond').addEventListener('FilePond:removefile', () => {
            this.form.Avatar = ""
            console.log(this.form.Avatar);
        });
    },
    created() {
        this.getProfile();
    },
};
</script>

<style scoped>
.red {
    color: red;
}

.hide-img {
    height: 0px;
    overflow: hidden;
    pointer-events: none;
    visibility: hidden;
    opacity: 0;
}
</style>
